import { mixins } from 'vue-class-component';
import { customerService } from '@/api';
import installationNoticeService from '@/api/installation-management/installation-notice';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { InstallationNoticeStatusEnum, PageQueryPlanEnum } from '@/resource/enum';
import { InstallationNoticeList, InstallationNoticeListQuery } from '@/resource/model';
import {
  dateFormat,
  downloadFileByBlob,
  limitPreviousForTimePicker,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { Component, Watch } from 'vue-property-decorator';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';
import { OsQueryPlan } from '@/components';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import EditAbstractContent from './edit-abstractContent/edit-abstractContent.vue';
import { NormalSelectOptions } from '@/resource/model/common';

@Component({
  name: 'InstallationNotice',
  components: { EditAbstractContent }
})
export default class InstallationNotice extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.installationNotice;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<InstallationNoticeList> = {
    loading: false,
    data: [],
    // fit: true,
    // size: 'small',
    // border: true,
    defaultSort: { order: 'descending', prop: 'createTime' }
  };
  public sortOptions: SortOptions<InstallationNoticeList> = this.tableOption.defaultSort!;

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption<InstallationNoticeListQuery>> = [
    {
      type: 'Input',
      field: 'code',
      label: 'installationNotice.code',
      option: {
        placeholder: 'deliveryNoticeList.inputCode'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'customer.customerName',
      option: {
        placeholder: 'customerTransfer.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    // {
    //   type: 'Input',
    //   field: 'projectName',
    //   label: 'project.name',
    //   option: {
    //     placeholder: 'project.inputName'
    //   }
    // },
    {
      type: 'Select',
      field: 'statusList',
      label: 'installationNotice.status',
      option: {
        placeholder: 'installationNotice.status',
        multiple: true,
        clearable: true
      },
      optionData: installationNoticeService.getStatusSelectableList()
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'installationNotice.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate: limitPreviousForTimePicker
        }
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'common.createUserName',
      option: {
        placeholder: 'common.inputCreateUserName'
      }
    },
    {
      type: 'Select',
      field: 'billStatus',
      label: 'installationNotice.billStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('installationNotice.reconciled'),
            value: 1
          },
          {
            label: translation('installationNotice.unreconciled'),
            value: 0
          }
        ];
      }
    }
  ];

  public defaultQueryForm: Partial<InstallationNoticeListQuery> = {
    createUserName: '',
    createTime: [],
    statusList: [],
    customerId: undefined,
    code: '',
    billStatus: undefined
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'batchSubmit',
      slot: 'start',
      label: 'installationNotice.batchSubmit',
      type: 'primary',
      icon: 'el-icon-s-promotion',
      disabled: true,
      permissionCode: 'install:notice:batchSubmit',
      handleClick: this.batchSubmit
    },
    {
      operationType: 'batchCancel',
      slot: 'start',
      label: 'installationOrder.batchCancel',
      icon: 'el-icon-close',
      disabled: true,
      permissionCode: 'install:notice:batchCancel',
      handleClick: this.batchCancel
    },
    {
      operationType: 'batchAudit',
      slot: 'start',
      label: 'installationNotice.batchAudit',
      type: 'primary',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'install:notice:batchAudit',
      handleClick: this.batchAudit
    },
    {
      operationType: 'batchReject',
      slot: 'start',
      label: 'installationNotice.batchReject',
      type: 'primary',
      icon: 'el-icon-close',
      disabled: true,
      permissionCode: 'install:notice:batchRevertAudit',
      handleClick: this.batchReject
    },
    {
      operationType: 'batchFinish',
      slot: 'start',
      label: 'installationNotice.batchFinish',
      type: 'primary',
      icon: 'el-icon-finished',
      disabled: true,
      permissionCode: 'install:notice:batchFinish',
      handleClick: this.batchFinish
    },
    {
      operationType: 'cancelFinish',
      slot: 'start',
      label: 'installationNotice.cancelComplete',
      type: 'primary',
      icon: 'el-icon-close',
      disabled: true,
      permissionCode: 'install:notice:cancelFinish',
      handleClick: this.cancelFinish
    },
    {
      operationType: 'batchDelete',
      slot: 'start',
      label: 'installationOrder.batchDelete',
      icon: 'el-icon-delete',
      disabled: true,
      permissionCode: 'install:notice:batchDelete',
      handleClick: this.batchDelete
    },
    {
      operationType: 'editSummary',
      slot: 'start',
      label: 'installationNotice.editSummary',
      type: 'primary',
      disabled: true,
      permissionCode: 'install:notice:editAbstract',
      handleClick: this.openEditSummary
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.exportDetail',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'install:notice:exportDetails',
      handleClick: this.export
    },
    {
      operationType: 'setReconciled',
      slot: 'start',
      label: 'installationNotice.setReconciled',
      type: 'primary',
      disabled: true,
      permissionCode: 'install:notice:submitBillStatus',
      handleClick: this.setReconciled
    },
    {
      operationType: 'setUnreconciled',
      slot: 'start',
      label: 'installationNotice.setUnreconciled',
      disabled: true,
      permissionCode: 'install:notice:cancelBillStatus',
      handleClick: this.setUnreconciled
    }
  ];

  /**
   * 表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<InstallationNoticeList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'code',
      label: 'installationNotice.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'abstractContent',
      label: 'installationNotice.orderSummary',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'projectName',
    //   label: 'installationNotice.projectName',
    //   minWidth: '180px',
    //   showOverflowTooltip: true,
    //   fixed: true
    // },
    {
      prop: 'customerName',
      label: 'installationNotice.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'installationNotice.status',
      minWidth: '100px',
      showOverflowTooltip: true,
      sortable: 'custom'
    },
    {
      prop: 'shopTotal',
      label: 'deliveryNoticeList.shopTotal',
      minWidth: '80px',
      showOverflowTooltip: true
    },
    {
      prop: 'orderTotal',
      label: 'installationNotice.orderTotal',
      minWidth: '80px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalBudgetAmount',
      label: 'addInstallationNotice.budgetAmount',
      minWidth: '80px',
      showOverflowTooltip: true
    },
    {
      prop: 'billStatus',
      label: 'installationNotice.billStatus',
      minWidth: '80px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        let str = '';
        switch ((rowData as InstallationNoticeList).billStatus) {
          case 1:
            str = translation('installationNotice.reconciled');
            break;
          case 0:
            str = translation('installationNotice.unreconciled');
            break;
          default:
            str = '--';
            break;
        }
        return str;
      }
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'installationNotice.createUserName',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'installationNotice.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      sortable: 'custom',
      formatter: (rowData: Object): string => {
        return dateFormat((rowData as InstallationNoticeList).createTime);
      }
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOptions: RowOperation<InstallationNoticeList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        size: 'small',
        permissionCode: 'install:notice:edit',
        handleClick: (row: InstallationNoticeList): void => {
          // 去编辑单据
          this.goEdit(row);
        },
        dynamicHidden: (rowData: InstallationNoticeList): boolean => {
          return Number(rowData.status) !== InstallationNoticeStatusEnum.new;
        }
      }
    ]
  };

  /**
   * 查询表单对象
   */
  public queryForm: Partial<InstallationNoticeListQuery> = {
    createUserName: undefined,
    customerId: undefined,
    code: undefined,
    projectName: undefined,
    statusList: [],
    endDate: undefined,
    startDate: undefined,
    billStatus: undefined,
    itemId: undefined
  };

  /**
   * 选中行数据
   */
  private selectedRows: Array<InstallationNoticeList> = [];

  /**
   * 编辑行
   */
  private editRow: InstallationNoticeList = {} as InstallationNoticeList;

  private dialogVisible: boolean = false;

  public activated(): void {
    if (this.$route.query.itemId) {
      // 路由携带查询条件参数，就路由的为主
      Object.assign(this.queryForm, {
        itemId: (this.$route.query.itemId as string) || ''
      });
      this.$router.push({ query: {} });
    }

    this.loadData();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'install-notice');
    this.initCustomSelectableList();
    this.loadData();
  }

  public linkToDetails(rowData: InstallationNoticeList): void {
    this.$router.push({
      path: '/installation-notice-detail',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.installationNoticeTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: InstallationNoticeStatusEnum): string {
    return installationNoticeService.getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: InstallationNoticeStatusEnum): string {
    return installationNoticeService.getStatusClass(status);
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<InstallationNoticeList>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<InstallationNoticeList>): void {
    this.tableToolbarOptions.forEach(option => {
      if (!['export'].includes(option.operationType)) {
        option.disabled = value.length === 0;
      }
    });
  }
  public handleSortChange(sortOptions: SortOptions<InstallationNoticeList>): void {
    this.sortOptions = sortOptions;
    this.paging.currentPage = 1;
    this.reloadData();
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0]);
      this.queryForm.endDate = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 数据加载
   */
  private loadData(): void {
    this.tableOption.loading = true;
    installationNoticeService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 初始化当前用户可选客户列表
   */
  private async initCustomSelectableList(): Promise<void> {
    const list = await customerService.getAllUsingCustomer();
    const selectableList: Array<any> = [];
    list.forEach(item => {
      selectableList.push({
        label: item.companyName,
        value: item.id
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'customerId');

    option!.optionData = selectableList;
  }

  /**
   * 批量提交
   */
  private batchSubmit(): void {
    const availableRows = this.selectedRows.filter(row => Number(row.status) === InstallationNoticeStatusEnum.new);
    if (availableRows.length <= 0) {
      Message.warning(translation('installationNotice.selectNewReceiptsTip'));
      return;
    }
    showWarningConfirm(translation('installationNotice.confirmPush'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationNoticeService.batchSubmit(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (errors) {
          messageErrors(errors);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    const availableRows = this.selectedRows.filter(row => Number(row.status) === InstallationNoticeStatusEnum.new);
    if (availableRows.length <= 0) {
      Message.warning(translation('installationNotice.selectNewReceiptsTip'));
      return;
    }

    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationNoticeService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 批量取消
   */
  private batchCancel(): void {
    const availableRows = this.selectedRows.filter(row =>
      [InstallationNoticeStatusEnum.waitingTake].includes(Number(row.status))
    );
    if (availableRows.length <= 0) {
      Message.warning(translation('installationNotice.selectWaitingAcceptOrderTip'));
      return;
    }

    showWarningConfirm(translation('installationNotice.confirmCancel'))
      .then(async () => {
        try {
          const idList: Array<number> = availableRows.map(row => row.id);
          await installationNoticeService.batchCancel(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 批量审核
   */
  private async batchAudit(): Promise<void> {
    const filteredIds = this.selectedRows
      .filter(x => x.status === InstallationNoticeStatusEnum.waitingTake)
      .map(x => x.id);
    if (filteredIds.length <= 0) {
      Message.warning(translation('installationNotice.selectWaitingAuditTip'));
      return;
    }
    try {
      await showWarningConfirm(translation('installationNotice.confirmTake'));
      await installationNoticeService.batchAudit(filteredIds);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }

  /**
   * 批量反审核
   */
  private async batchReject(): Promise<void> {
    const filteredIds = this.selectedRows
      .filter(x => x.status === InstallationNoticeStatusEnum.waitingInstall)
      .map(x => x.id);
    if (filteredIds.length <= 0) {
      Message.warning(translation('installationNotice.selectWaitingInstallTip'));
      return;
    }
    try {
      await showWarningConfirm(translation('installationNotice.confirmReject'));
      await installationNoticeService.batchReject(filteredIds);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }

  /**
   * 批量完成
   */
  private async batchFinish(): Promise<void> {
    const filteredIds = this.selectedRows
      .filter(x =>
        [InstallationNoticeStatusEnum.partialInstall, InstallationNoticeStatusEnum.waitingInstall].includes(x.status)
      )
      .map(x => x.id);
    if (filteredIds.length <= 0) {
      Message.warning(translation('installationNotice.selectPartialInstallTip'));
      return;
    }
    try {
      await showWarningConfirm(translation('installationNotice.confirmFinishInstall'));
      await installationNoticeService.batchFinish(filteredIds);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }
  /**
   * 批量反完成
   */
  private async cancelFinish(): Promise<void> {
    const filteredIds = this.selectedRows
      .filter(x => x.status === InstallationNoticeStatusEnum.completed)
      .map(x => x.id);
    if (filteredIds.length <= 0) {
      Message.warning(translation('installationNotice.selectCompleted'));
      return;
    }
    try {
      await showWarningConfirm(translation('installationNotice.confirmCancelFinishInstall'));
      await installationNoticeService.cancelFinish(filteredIds);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.operationCanceled'));
      } else {
        messageErrors(error);
      }
    }
  }

  /**
   * 去编辑
   * @param rowData 编辑订单对象
   */
  private goEdit(rowData: InstallationNoticeList): void {
    this.$router.push({
      path: '/add-installation-notice',
      query: {
        id: rowData.id.toString()
      }
    });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const ids = this.selectedRows.map(x => x.id);
      const blob = await installationNoticeService.export(this.queryForm, ids);
      downloadFileByBlob(
        `${translation('installationNotice.installationNoticeFile')}_${dateFormat(new Date())}.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 设置已对账
   */
  private async setReconciled(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows.map(row => row.id);
      await installationNoticeService.submitBillStatus(idList);
      this.reloadData();
      Message.success(translation('operationRes.setSuccess'));
    } catch (error) {
      messageErrors(error);
    }
  }
  /**
   * 设置未对账
   */
  private async setUnreconciled(): Promise<void> {
    try {
      const idList: Array<number> = this.selectedRows.map(row => row.id);
      await installationNoticeService.cancelBillStatus(idList);
      this.reloadData();
      Message.success(translation('operationRes.setSuccess'));
    } catch (error) {
      messageErrors(error);
    }
  }

  /**
   * 打开编辑单据摘要
   */

  private openEditSummary(): void {
    // 只能单条修改
    if (this.selectedRows.length !== 1) {
      Message.error(translation('installationNotice.selectSingleData'));
      return;
    }
    this.editRow = this.selectedRows[0];
    this.dialogVisible = true;
  }
  private dialogClosed(): void {
    this.editRow = {} as InstallationNoticeList;
    this.dialogVisible = false;
    this.reloadData();
  }
}
