import { installationNoticeService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { InstallationNoticeList } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'EditAbstractContent',
  components: {}
})
export default class EditAbstractContent extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return null;
    }
  })
  public position!: InstallationNoticeList;

  public form: { id: number; abstractContent: string } = {
    id: 0,
    abstractContent: ''
  };

  public formRules = {
    abstractContent: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('installationNotice.inputAbstractContent')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  private operationType: 'add' | 'edit' = 'add';

  public dialogOpen(): void {
    if (this.position) {
      this.operationType = 'edit';
      this.title = 'installationNotice.editSummary';
      this.form.abstractContent = this.position.abstractContent;
      return;
    }
    this.operationType = 'add';
    this.title = 'installationNotice.editSummary';
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.formRef as Form).resetFields();
  }

  public onSubmit(): void {
    this.form.id = this.position.id;
    (this.$refs.formRef as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        await installationNoticeService.editSummary({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success', this.form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
